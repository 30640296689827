import { ConfigurableProduct, Money } from '@magentoTypes'
import { useCallback } from 'react'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'

export const useProductTracking = () => {
  const { dataLayer } = useGTM()

  const triggerProductEvent = useCallback(
    (eventName: string, product: Partial<ConfigurableProduct>, price?: Money) => {
      dataLayer(DataLayerEnum.EVENT, eventName, {
        ecommerce: {
          currency: price?.currency || 'EUR',
          items: [
            {
              item_id: product?.sku,
              item_name: product.name,
              price: price?.value,
            },
          ],
        },
      })
    },
    [dataLayer],
  )

  return { triggerProductEvent }
}
