import { CurrencyEnum, ProductPrice } from '@magentoTypes'

export const mapPrice = (
  type: 'min' | 'max',
  price: number,
  specialPrice: number | string,
  currencyCode: string = 'EUR',
  variations: Array<Record<string, any>> = [],
): ProductPrice => {
  if (variations.length > 0) {
    const prices = variations.map((variation) => variation?.data?.price).filter(Number)
    if (type === 'min') {
      const foundPrice = Math.min(...prices)
      return {
        regular_price: { value: foundPrice },
        final_price: { value: foundPrice },
      }
    }

    const foundPrice = Math.max(...prices)
    return {
      regular_price: { value: foundPrice },
      final_price: { value: foundPrice },
    }
  }
  return {
    regular_price: {
      value: price,
      currency: currencyCode as CurrencyEnum,
    },
    // Explanation of this weird check:
    // because of constructor.io logic that prevents us from removing value from object attribute (eg. there is special price but then promo ends and special price should be reomved - this cannot be done in cio).
    // We need to insert some strange value so we can display proper prices(omit special price if "-" is the value of it)
    // Ticket: https://creativestyle.atlassian.net/browse/BEN-514
    final_price: {
      value: specialPrice !== '-' ? (specialPrice as number) || (price as number) : price,
      currency: currencyCode as CurrencyEnum,
    },
  }
}
